import React, { useState } from "react";
import AllContent from "./AllContent";

const Content = ({ ual }) => {
  return (
    <div>
      <div className="flex flex-wrap items-center justify-between pb-6 md:flex-row md:items-start md:gap-y-5">
        <AllContent ual={ual} />
      </div>
    </div>
  );
};

export default Content;
