import React from "react";
import Content from "./Content";
import NPCBg from "assets/images/npc.jpg";

const NPC = ({ ual }) => {
  return (
    <div className="py-10 md:min-h-screen">
      <img src={NPCBg} style={{ position: 'fixed', top: 0, left: 0, width: "100%", height: "100vh", opacity: '1', zIndex: "0" }} />

      <div className="container mx-auto" style={{ backgroundSize: "cover", position: "relative" }}>
        <Content ual={ual} />
      </div>
    </div>
  );
};

export default NPC;
