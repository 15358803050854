import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSound } from "SoundContext";
import soundFile from "assets/sound/npc_fight.mp3";
import { useError } from "ErrorProvider";

import WaxIcon from "assets/images/icons/waxp.png";
import anonymous from "assets/images/npc/anonymous.jpg";
import tiny from "assets/images/npc/tiny.jpg";
import leslie from "assets/images/npc/leslie.jpg";
import duke from "assets/images/npc/duke.jpg";
import maol from "assets/images/npc/maol.jpg";
import ResultsPopUp from "./ResultsPopUp";

import {
  refreshAll,
  anchorGetReports,
  anchorChallengeNPC,
  anchorGetWaxFromPvp,
} from "BlockchainInteractionWax";

const AllContent = ({ ual }) => {
  const [results, setResults] = useState([
    { id: 0, attacker: "", defender: "", winner: "", event_log: [] },
  ]);
  const [isOpenResults, setIsOpenResults] = useState(false);
  const [selectedLog, setSelectedLog] = useState([]);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [waxWallet, setWaxWallet] = useState(0);

  const { isMuted } = useSound();
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();

  const characters = [
    {
      name: "Anonymous",
      image: anonymous,
      description: (
        <>
          Shrouded in mystery, Anonymous is an enigma with no past, no visible motives.
          Rumored to have unparalleled skills honed in isolation, this fighter challenges anyone daring enough to uncover their secrets.          
          Some say Anonymous is a shadow of a fallen champion, seeking redemption.
          <br />
          <strong>Anonymous has a very low chance of dropping a Common Fight Club Badge, 3 WAX or 3 HDC!</strong>
          <br />
          <strong>Cost to challenge: Free</strong>
        </>
      ),
      index: 0
    },
    {
      name: "Tiny",
      image: tiny,
      description:
      (
        <>
         Don't let Tiny's name fool you. Known for their towering presence and brute strength, Tiny was once a lumberjack who
         turned to the arena for thrill and fame. Their punches are said to feel like a falling tree. Despite their intimidating size,
         Tiny has a soft spot for their pet rabbit, Fluff.         
        <br />
        <strong>Tiny has a low chance of dropping a Fight Club Crate, a slim chance of dropping 5 WAX or 5 HDC and a average chance to drop a Common Fight Club Badge!</strong>
        <br />
        <strong>Cost to challenge: 3 HDC / 2 WAX</strong>
        </>
      ),
      index: 1
    },
    {
      name: "Leslie",
      image: leslie,
      description:
      (
        <>
         A cunning strategist and master of deception, Leslie has always relied on wit over brawn. Once a con artist,
         Leslie entered the tournament circuit to outwit the best and claim the prize. With sharp reflexes and a sharper tongue,
         Leslie dances around opponents with ease.
        <br />
        <strong>Leslie has a low chance of dropping a Fight Club Crate, a slim chance of dropping 10 WAX or 5 HDC and a average chance to drop a Rare Fight Club Badge!</strong>
        <br />
        <strong>Cost to challenge: 3 HDC / 2 WAX</strong>
        </>
      ),
      index: 2
    },
    {
      name: "Duke",
      image: duke,
      description:
      (
        <>
         Duke hails from a long line of warriors and carries their legacy with pride. Armed with unparalleled combat skills
          and a noble code of honor, Duke fights not for glory but to prove that tradition and discipline can still conquer the modern age.
        <br />
        <strong>Duke has a low chance of dropping a Fight Club Crate, a slim chance of dropping 100 WAX or 5 HDC and a average chance to drop a Epic Fight Club Badge!</strong>
        <br />
        <strong>Cost to challenge: 3 HDC / 2 WAX</strong>
        </>
      ),
      index: 3
    },
    {
      name: "Ma'ol",
      image: maol,
      description:
      (
        <>
        Known as the “Phantom of the Arena,” Ma'ol is a former bounty hunter turned fighter. Their expertise lies in agility and precision, 
        making every move calculated. They fight with a purpose unknown to others, but their piercing gaze reveals a deep resolve.        
        <br />
        <strong>Ma'ol has a slim chance of dropping a Fight Club Crate, a average chance of dropping 1000 WAX or 5 HDC and a good chance to drop a Legendary Fight Club Badge!</strong>
        <br />
        <strong>Cost to challenge: 3 HDC / 2 WAX</strong>
        </>
      ),
      index: 4
    },
  ];

  const fetchData = useCallback(async () => {
    if (ual.activeUser && store) {
      const allResults = await anchorGetReports(ual);
      setResults(allResults);
      const values1 = await anchorGetWaxFromPvp(ual);
      setWaxWallet(parseFloat(values1).toFixed(3));
    }
  }, [ual, store]);

  useEffect(() => {
    fetchData();
  }, [store, ual]);

  async function challengeNPC(index, type) {
    const bet = [];

    if (index !== 0) {
      if (type === 0) {
        bet.push(3);
        bet.push(0);
      } else {
        bet.push(0);
        bet.push(2);
      }
    } else {
      bet.push(0);
      bet.push(0);
    }

    await anchorChallengeNPC(ual, index, bet, setError);
    if (!isMuted) {
      const audio = new Audio(soundFile);
      audio.play().catch((error) => {
        console.error("Audio playback failed:", error);
      });
    }
    await new Promise((resolve) => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  return (
    <div className="w-full h-full pt-8 px-4 flex flex-col lg:flex-row gap-4">
  {/* Left: WAX Wallet, Dropdown, and Reports Section */}
  <div className="w-full lg:w-1/4 bg-[#2F2F2E] bg-opacity-80 rounded-md p-4 flex flex-col">
    {/* WAX Wallet Display */}
    <div className="flex items-center justify-center mb-4 space-x-2">      
      <span className="text-white text-xl font-bold">Ingame WAX: {waxWallet}</span>
      <img src={WaxIcon} alt="WAX Icon" className="h-8 w-8 mb-2" />
    </div>
    <div className="flex items-center justify-center mb-4 space-x-2">
    <span className="text-white text-xl font-bold">Weekly reward pool: 25000</span>
    <img src={WaxIcon} alt="WAX Icon" className="h-8 w-8" />
    </div>

    {/* Character Selection Combobox */}
    <div className="flex flex-col items-center mb-4">
      <select
        className="bg-[#1a1a1a] text-white rounded-md p-2 w-40 text-center"
        onChange={(e) =>
          setSelectedCharacter(
            characters.find((char) => char.name === e.target.value)
          )
        }
      >
        <option value="">Select an NPC</option>
        {characters.map((character, index) => (
          <option key={index} value={character.name}>
            {character.name}
          </option>
        ))}
      </select>
    </div>

    {/* Reports Scroll Area */}
    <div className="h-[42vh] overflow-y-scroll bg-[#2F2F2E] bg-opacity-80 rounded-md p-2">
      <span className="text-white text-xl text-center font-extrabold shadow-lg mb-2 block">
        Reports
      </span>
      {results.length > 0 ? (
        results
          .sort((a, b) => b.id - a.id) // Sort by id in descending order
          .map(({ id, attacker, defender, winner, event_log }, index) => (
            <div
              key={id || index}
              className={`flex items-center justify-between p-2 text-white border-b border-gray-600 last:border-b-0 ${
                ual.activeUser?.accountName &&
                winner === ual.activeUser.accountName
                  ? "bg-green-700"
                  : "bg-red-700"
              }`}
            >
              <span className="text-white text-sm">
                {ual.activeUser?.accountName === attacker
                  ? defender
                  : attacker}{" "}
                Fight: {id}
              </span>

              <button
                className="bg-[#29c4cc] bg-opacity-80 text-white rounded-md px-3 py-1 w-24 text-sm"
                onClick={() => {
                  setSelectedLog(event_log); // Set the selected event log
                  setIsOpenResults(true); // Open the results popup
                }}
              >
                Results
              </button>
            </div>
          ))
      ) : (
        <p className="text-center text-white mt-4">
          No fights in the tournament yet
        </p>
      )}
      <ResultsPopUp
        isOpen={isOpenResults}
        setIsOpen={setIsOpenResults}
        ual={ual}
        log={selectedLog}
      />
    </div>
  </div>

  {/* Right: Selected Character Section */}
  <div className="w-full lg:w-3/4 bg-[#2F2F2E] bg-opacity-80 rounded-md p-4 flex flex-col items-center justify-center">
    {selectedCharacter ? (
      <>
        {/* Large Character Image */}
        <img
          src={selectedCharacter.image}
          alt={selectedCharacter.name}
          className="rounded-md object-contain w-full lg:w-2/3 h-96"
        />
        {/* Character Name */}
        <span className="text-white text-3xl font-bold mt-4">
          {selectedCharacter.name}
        </span>
        {/* Character Description */}
        <p className="text-gray-300 text-lg text-center mt-4">
          {selectedCharacter.description}
        </p>
        {/* Buttons */}
        <div className="flex space-x-4 mt-6">
          <button
            className="bg-[#25a11a] bg-opacity-80 text-white rounded-md px-6 py-3 text-lg"
            onClick={() =>
              challengeNPC(selectedCharacter.index, 0)
            }
          >
            {selectedCharacter.index === 0 ? "FREE" : "HDC"}
          </button>
          {selectedCharacter.index !== 0 && (
          <button
            className="bg-[#1a75a1] bg-opacity-80 text-white rounded-md px-6 py-3 text-lg"
            onClick={() =>
              challengeNPC(selectedCharacter.index, 1)
            }
          >
            {selectedCharacter.index === 0 ? "FREE" : "WAX"}
          </button>)}
        </div>
      </>
    ) : (
      <p className="text-white text-center text-2xl">
        Select a NPC to see their details.
      </p>
    )}
  </div>
</div>


  );
};

export default AllContent;
