import React, { Component } from "react";
import ImgCharacter from "assets/images/home/battle_character.png";

class Battle extends Component {
  render() {
    return (
      <div className="flex w-full flex-col items-start justify-center py-16 px-5 md:flex-row md:px-10 md:py-12">
        <div className="order-1 w-full md:order-2 md:w-1/2 lg:mx-12">
          <img src={ImgCharacter} alt="Gang Managemnet" />
        </div>
        <div className="order-2 flex w-full flex-col gap-y-5 md:order-1 md:w-1/2">
          <h2 className="text-3xl mx-8 md:mx-5 font-bold text-primary md:text-5xl font-Montserrat">
            Battle
          </h2>
          <p className="text-md mx-8 md:mx-5 leading-7 text-white md:text-xl md:leading-6 lg:leading-7 xl:leading-10">
          Unleash your collection’s full potential in Fight Club! Create a unique strategies, train your characters, 
          and compete in epic player-vs-player showdowns to claim valuable resources from your rivals. Take on NPCs for a 
          chance to win $HDC, $WAX, or rare NFTs. Every battle is a test of wit, skill, and ambition. Join now for free and rise to the top in The Hustle!
          </p>
        </div>
      </div>
    );
  }
}

export default Battle;
