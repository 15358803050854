import React, { useState } from "react";
import MenuItem from "./MenuItem";

const GangWarsMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menusList = [
    {
      id: 1,
      label: "Character",
      url: "/character",
    },
    {
     id: 2,
     label: "PvP",
     url: "/pvp",
    },
    {
      id: 3,
      label: "Tournaments",
      url: "/tournaments",
     },
     {
      id: 4,
      label: "NPC Fights",
      url: "/npc",
     },
  ];

  const handleItemClick = () => {
    setIsOpen(false);
  };

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="">
      <div onClick={handleMenuClick}>
        <MenuItem text={"Fight Club"} noLink />
      </div>
      <ul className={`ml-6 ${isOpen ? "block" : "hidden"}`}>
        {menusList.map((item) => (
          <li key={item.id}>
            <MenuItem text={item.label} url={item.url} onClick={handleItemClick} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GangWarsMenu;

