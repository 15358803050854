import React, { useEffect } from "react";
import '@neftyblocks/market';

const MarketNefty = ({ ual }) => {

    useEffect(() => {
        
      }, []);

  return (
    <neftyblocks-market collection="thehustlewax"></neftyblocks-market>
  );
}

export default MarketNefty;
