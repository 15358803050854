import React, { Component } from "react";
import characters from "assets/images/home/characters.png";


class NFT extends Component {
  render() {
    return (
      <div className="px-8 flex w-full flex-col items-start justify-center gap-x-1 py-16 md:flex-row md:px-5 md:py-24 md:px-10">
        <div className="flex w-full flex-wrap items-center justify-center md:w-1/2 md:flex-row md:items-end md:gap-x-1">
        <div className="order-1 w-full md:order-2 lg:mx-12">
          <img src={characters} alt="Gang Managemnet" />
        </div>
        </div>
        <div className="flex w-full flex-col gap-y-5 p-5 md:w-1/2">
          <h2 className="text-3xl font-bold text-primary md:text-5xl font-Montserrat">Collect</h2>
          <p className="text-md leading-8 text-white md:text-xl  xl:leading-10">
          Join Hustle Nation by collecting unique characters released throughout the seasons. These characters are more than just collectibles they're 
          your ticket to exclusive perks like giveaways, airdrops, and community events. Plus, as a member of Hustle Nation, you earn royalties derived 
          from a percentage of the season's revenue. Owning a character means owning a piece of the action!
          </p>
        </div>
      </div>
    );
  }
}

export default NFT;
