import React, { useState, useEffect, useCallback, useMemo, Component } from "react";
import { FaTimes } from "react-icons/fa";
import WaxIcon from "assets/images/icons/waxp.png";
import Modal from "components/shared/Modal";
import { useError } from 'ErrorProvider';

import { useDispatch, useSelector } from "react-redux";
import {anchorGetWaxFromWallet, resetStats, refreshAll} from "BlockchainInteractionWax.js"

const ResetStatsPopUp = ({ isOpen, setIsOpen, ual, asset_id, resets }) => {
  const [waxWallet, setWaxWallet] = useState(0);
  const [waxAmount, setWaxAmount] = useState("0.0000");

  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();

  const fetchData = useCallback(async () => {
    if (ual.activeUser && store) {
      const values = await anchorGetWaxFromWallet(ual)
      setWaxWallet(parseFloat(values[0].value).toFixed(3))
    }
  }, [ual, store]); 

  useEffect(() => {
    fetchData();
  }, [fetchData, store]);

  async function handleResetStatsClick()
  {

    await resetStats(ual, asset_id, setError)   
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch)
    setIsOpen(false);    
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
      <div
        className="w-full rounded-xl py-4 px-6 font-Montserrat py-4 px-6"
        style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}
      >
        <form>
          <div className="mb-2 flex items-center justify-between">
            <div />
            <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-white">
              Reset Stats
            </h2>
            <FaTimes
              className="block text-2xl text-white hover:cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="text-center text-white"></div>
          <div>
            <div className="flex items-center justify-center gap-x-2 text-white">
                <label className="text-white">Cost to reset: {1 }</label>  {/*+ 1 * resets*/}
                <div className="flex items-center">
                <img
                    src={WaxIcon}
                    alt="icon"
                    className="h-8 w-8 object-contain" // Adjusted size to align with text height
                />
                </div>            
            </div>
            </div>

          <div className="mt-5 flex justify-center">
          <div className='buttonContainer'>
            <button className="w-40 py-1 px-3"
            onClick={(e) => {e.preventDefault(); handleResetStatsClick()}}>Reset Stats</button>
            /</div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ResetStatsPopUp;
