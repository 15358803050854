import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import OutsideClickHandler from 'react-outside-click-handler';
import { anchorStakeNft, anchorUnstakeNFT, anchorUnpack, anchorClaimPack, getRollIds, anchorGetUnpack } from "BlockchainInteractionWax";
import CheckBox from 'components/shared/CheckBox';
import DotsIcon from 'assets/images/icons/dots.png';
import InfoIcon from 'assets/images/icons/information.png';
import Stats from 'components/Stash/Stats'
import { useError } from 'ErrorProvider';

const NFT = ({ type = 'equip', checked, onCheck, refresh, ual, ...item }) => {

  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  const [isOpenTooltip, setIsOpenTooltip] = useState(false);
  const [isOpenActionsMenu, setIsOpenActionsMenu] = useState(false);
  const [isStatsOpen, setIsStatsOpen] = useState(false);
  const [packUnboxed, setPacksUnboxed] = useState([])

  const handleTransfer = () => {
    setIsOpenActionsMenu(false);
  }

  const fetchData = async () => {
    if (ual.activeUser) {

    }
  };

  useEffect(() => {
    fetchData();
  }, [store, ual.activeUser]);

  const { setError } = useError();

  const handleStake = async (id) => {
    setIsOpenActionsMenu(false);
    await anchorStakeNft(ual.activeUser.accountName, id, ual, setError)
    refresh('stake', [id]);
  }

  async function  handleUnpackOrUnbox(item) {

    const pack = [];
    pack.push(item.asset_id)

    if(item.is_unbox === true)
    {
      const origin_roll_ids_count = await getRollIds(pack, ual, setError)
      const origin_roll_ids = []
      for (let i = 0; i < origin_roll_ids_count; i++) {
        origin_roll_ids.push(i);
      } 
      await anchorClaimPack(ual, item.asset_id, origin_roll_ids, setError)
    }   
    else
    {      
      await anchorUnpack(ual, pack, setError)
    }   
    
    setIsOpenActionsMenu(false);
  }

  const handleUnstake = async (id) => {
    setIsOpenActionsMenu(false);
    await anchorUnstakeNFT(ual, id, setError)
    refresh('unstake', [id]);
  }

  const openInfo = (type) => {
    setIsStatsOpen(true)
  }

  return <div className="flex flex-col items-center border border-[#2F2F2E] text-lime-400 hover:bg-[#252525] rounded-[30px] p-4 pb-2 relative">

    <div className='w-full flex justify-center mb-2 relative'>
      <img
        src={InfoIcon}
        className="inline-block w-6 h-6 invert rounded-full cursor-pointer"
        // onMouseOver={() => setIsOpenTooltip(true)}
        onMouseLeave={() => setIsOpenTooltip(false)}
        onClick={() => openInfo(item.schema)}
      />
      {
        isOpenTooltip && <div className="w-full text-white text-sm absolute left-0 top-10 border border-[#2F2F2E] bg-[#131312] hover:bg-[#252525] rounded-lg p-3">
          {item.description}
        </div>
      }
    </div>
    <img
      src={`https://ipfs.io/ipfs/${item.image}`}
      alt="Decoded image"
      className="w-full h-56 object-contain object-center"
    />
    <div className="w-full flex justify-center relative mt-2">
      <OutsideClickHandler
        onOutsideClick={() => setIsOpenActionsMenu(false)}
      >
        <img
          src={DotsIcon}
          className="w-6 h-6 invert rounded-full cursor-pointer"
          onClick={() => setIsOpenActionsMenu(true)}
        />
        {
          isOpenActionsMenu && <ul className="w-full absolute border border-[#2F2F2E] p-1 rounded-2xl top-10 bg-[#252525] z-20 left-0">
            {
              item.schema !== 'packs' ?
                <>
                  {
                    type === 'equip' ? <>
                      <li
                        onClick={() => handleStake(item.asset_id)}
                        className="text-center font-semibold text-base leading-10 rounded-xl bg-transparent text-lime-400 hover:text-money-green hover:bg-white transition cursor-pointer"
                      >
                        Equip
                      </li>
                      <li
                        onClick={handleTransfer}
                        className="text-center font-semibold text-base leading-10 rounded-xl bg-transparent text-lime-400 hover:text-money-green hover:bg-white transition cursor-pointer"
                      >
                        Transfer
                      </li>
                    </>
                      : <li
                        onClick={() => handleUnstake(item.asset_id)}
                        className="text-center font-semibold text-base leading-10 rounded-xl bg-transparent text-lime-400 hover:text-money-green hover:bg-white transition cursor-pointer"
                      >
                        Unequip 
                      </li>
                  }
                </>
                : <li
                onClick={() => handleUnpackOrUnbox(item)} // Call the function that handles both cases
                className="text-center font-semibold text-base leading-10 rounded-xl bg-transparent text-lime-400 hover:text-money-green hover:bg-white transition cursor-pointer"
              >
                {item.is_unbox ? "Unbox" : "Unpack"} {/* Dynamically set the button text */}
              </li>
            }
          </ul>
        }
        <CheckBox
          checked={checked}
          onChange={() => onCheck(item.asset_id)}
          className='absolute top-4 left-4 mt-[-280px] -ml-3'
        />
      </OutsideClickHandler>
      {isStatsOpen && <Stats isOpen={isStatsOpen} setIsOpen={setIsStatsOpen} ual={ual} assetId={item.asset_id} item={item} />}
      
    </div>
  </div>
}

export default NFT;