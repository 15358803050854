import React, { useEffect } from "react";
import '@neftyblocks/drops';

const DropsNefty = ({ ual }) => {

    useEffect(() => {
        
      }, []);

  return (
    <neftyblocks-drops collection="thehustlewax"></neftyblocks-drops>
  );
}

export default DropsNefty;
