import React, { useState, useEffect, useCallback, useMemo, Component } from "react";
import { FaTimes } from "react-icons/fa";
import WaxIcon from "assets/images/icons/waxp.png";
import Modal from "components/shared/Modal";
import { useError } from 'ErrorProvider';
import { useSound } from "SoundContext";
import donate from "assets/sound/donate.mp3";

import { useDispatch, useSelector } from "react-redux";
import {anchorGetWaxFromWallet, anchorGetWaxFromPvp, refreshAll, donateTournament} from "BlockchainInteractionWax.js"

const DonateWaxPopup = ({ isOpen, setIsOpen, ual, tournament }) => {
  const [waxWallet, setWaxWallet] = useState(0);
  const [waxAmount, setWaxAmount] = useState("0.0000");

  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();
  const { isMuted } = useSound();

  const fetchData = useCallback(async () => {
    if (ual.activeUser && store) {
      const values = await anchorGetWaxFromPvp(ual)
      setWaxWallet(parseFloat(values).toFixed(3))
    }
  }, [ual, store]); 

  useEffect(() => {
    fetchData();
  }, [fetchData, store]);

  async function handleDonateWaxClick()
  {
    await donateTournament(ual, tournament, waxAmount, setError)
    if (!isMuted) {
      const audio = new Audio(donate);
      audio.play().catch((error) => {
        console.error("Audio playback failed:", error);
      });
    }
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch)
    setIsOpen(false);    
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
      <div
        className="w-full rounded-xl py-4 px-6 font-Montserrat py-4 px-6"
        style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}
      >
        <form>
          <div className="mb-2 flex items-center justify-between">
            <div />
            <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-white">
              Donate Wax
            </h2>
            <FaTimes
              className="block text-2xl text-white hover:cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="text-center text-white"></div>
          <div>
            <div className=" flex items-center justify-end gap-x-2 text-white">              
            </div>
            <div className=" flex items-center justify-end gap-x-2 text-white">              
              <p className="mt-2">{parseFloat(waxWallet).toFixed(3)}</p>
              <div className="h-10 w-14">
                <img
                  src={WaxIcon}
                  alt="icon"
                  className="object- h-full w-auto"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 justify-center">
            <div className="flex flex-col justify-center">
              <label className="text-white h-10">Wax:</label>
            </div>
            <div className="flex flex-col justify-center flex-1 ml-2">
              <div className="flex flex-row items-center">
                <input
                  type="number"
                  className="rounded-lg border border-gray-400 p-2 w-full sm:w-48 h-10 mb-2"

                  value={waxAmount}
                  onChange={(event) => setWaxAmount(event.target.value)}
                  placeholder="Enter amount to deposit"
                  step="0.0001"
                  min="0"
                />
                <div className="h-10 w-14">
                  <img
                    src={WaxIcon}
                    alt="icon"
                    className="object- h-full w-full"
                  />
                </div>
              </div>
            </div>            
          </div>
        </div>
        <h2 className="px-5 py-2 text-center font-Montserrat text-small font-bold text-white flex">
              Donating grants your equipped NFT 10XP per 1 WAX
            </h2>
          <div className="mt-5 flex justify-center">
          <div className='buttonContainer'>
            <button className="w-40 py-1 px-3"
            onClick={(e) => {e.preventDefault(); handleDonateWaxClick()}}>Donate Wax</button>
            /</div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default DonateWaxPopup;
